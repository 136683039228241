form {
    color: white;
}

input {
    display: flex;
    justify-content: flex-start;
    align-content: flex-end;
    margin-bottom: 2%;
    margin-top: 1%;
}

button {
    align-content: flex-end;
}